var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('div',[_c('b-card',{attrs:{"title":"Informations générales"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"basicInput","plaintext":_vm.readOnly,"placeholder":"Nom","state":errors.length > 0 ? false:null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Photo","label-for":"Photo"}},[(_vm.file!==null)?_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-img',{attrs:{"src":_vm.file,"blank-color":"#ccc","width":"64","alt":"placeholder"}})]},proxy:true}],null,false,2264326756)},[_c('b-card-text',{staticClass:"mb-0"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"size":"sm","variant":"outline-warning"},on:{"click":function($event){return _vm.editPhoto()}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1)],1)],1):_c('b-form-file',{attrs:{"required":"","state":Boolean(_vm.file),"placeholder":"Choisissez ou faites glisser un fichier...","drop-placeholder":"Déposer ici ..."},on:{"change":function($event){return _vm.fileAdded($event)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Stock","label-for":"Stock"}},[_c('validation-provider',{attrs:{"name":"Stock","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Stock","plaintext":_vm.readOnly,"type":"number","step":"1","state":errors.length > 0 ? false:null,"placeholder":"Stock"},model:{value:(_vm.form.stock),callback:function ($$v) {_vm.$set(_vm.form, "stock", $$v)},expression:"form.stock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Prix","label-for":"Prix"}},[_c('validation-provider',{attrs:{"name":"Prix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"Prix","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"type":"number","step":"0.01","placeholder":"Prix"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Catégorie","label-for":"Type"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","multiple":"","options":_vm.tintCatChoices},model:{value:(_vm.form.tint_categories),callback:function ($$v) {_vm.$set(_vm.form, "tint_categories", $$v)},expression:"form.tint_categories"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Options de verre","label-for":"Type de verre"}},[_c('validation-provider',{attrs:{"name":"Options de verre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","state":errors.length > 0 ? false:null,"multiple":"","options":_vm.glassOptionChoices},model:{value:(_vm.form.glass_options),callback:function ($$v) {_vm.$set(_vm.form, "glass_options", $$v)},expression:"form.glass_options"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Code","label-for":"Code"}},[_c('validation-provider',{attrs:{"name":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"required":"","id":"Code","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"#ffffff"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Teinte (hue)","label-for":"Teinte"}},[_c('validation-provider',{attrs:{"name":"Teinte"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Hue","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.form.hue),callback:function ($$v) {_vm.$set(_vm.form, "hue", $$v)},expression:"form.hue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Teinte (hue) pour app","label-for":"Teinte"}},[_c('validation-provider',{attrs:{"name":"Teinte"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Hue","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.form.hue_app),callback:function ($$v) {_vm.$set(_vm.form, "hue_app", $$v)},expression:"form.hue_app"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Saturation","label-for":"Saturation"}},[_c('validation-provider',{attrs:{"name":"Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Saturation","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.form.saturation),callback:function ($$v) {_vm.$set(_vm.form, "saturation", $$v)},expression:"form.saturation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Opacity","label-for":"Opacity"}},[_c('validation-provider',{attrs:{"name":"Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Opacity","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.form.opacity),callback:function ($$v) {_vm.$set(_vm.form, "opacity", $$v)},expression:"form.opacity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Luminosité","label-for":"Luminosité"}},[_c('validation-provider',{attrs:{"name":"Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Luminosité","plaintext":_vm.readOnly,"state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.form.brightness),callback:function ($$v) {_vm.$set(_vm.form, "brightness", $$v)},expression:"form.brightness"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"Type"}},[_c('validation-provider',{attrs:{"name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","plaintext":_vm.readOnly,"placeholder":"Type","state":errors.length > 0 ? false:null},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Ordre d'affichage","label-for":"Ordre d'affichage"}},[_c('b-form-input',{attrs:{"id":"Ordre d'affichage","plaintext":_vm.readOnly,"type":"number","step":"1","placeholder":"Ordre d'affichage"},model:{value:(_vm.form.order_display),callback:function ($$v) {_vm.$set(_vm.form, "order_display", $$v)},expression:"form.order_display"}})],1)],1)],1)],1)],1)],1),(_vm.edition==false)?_c('b-button',{on:{"click":_vm.createTint}},[_vm._v(" Enregistrer ")]):_c('b-button',{on:{"click":_vm.editTint}},[_vm._v(" Enregistrer ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }